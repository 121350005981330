import type { MergedUnion } from "@kablamo/kerosene";
import type { LayerSet } from "../../../config/layers/layers";
import type { Toolset } from "../../../config/tools";
import MouseProvider from "../../../state/MouseProvider";
import MapProvider, { type MapBounds } from "../../map/Map/MapProvider";
import MapInteractionsProvider from "../../map/MapInteractions/MapInteractions";
import { HimawariTemperatureProvider } from "../../util/HimawariTemperatureProvider/HimawariTemperatureProvider";
import TimelineProvider from "../../util/TimelineProvider/TimelineProvider";
import PredictionsProvider from "../PredictionsProvider/PredictionsProvider";
import VisualiserProvider, { type VisualiserState } from "./VisualiserProvider";

type VisualiserProps = {
  children?: React.ReactNode;
  defaultHours?: number;
  defaultStart?: number;
  initialBounds?: MapBounds;
  initialVisualiserState?: Partial<VisualiserState>;
  isStatic?: boolean;
  onBoundsChange?: (bounds: MapBounds) => void;
  toolsets?: Toolset[];
  layerSets: LayerSet[];
} & MergedUnion<
  | {
      lib: "mapbox";
      mapboxAccessToken: string;
    }
  | {
      lib: "maplibre";
    }
>;

const Visualiser = ({
  children,
  defaultHours,
  defaultStart,
  initialBounds,
  initialVisualiserState,
  isStatic,
  lib,
  mapboxAccessToken,
  onBoundsChange,
  toolsets,
  layerSets,
}: VisualiserProps) => {
  const mapProviderProps =
    lib === "mapbox" ? { lib, accessToken: mapboxAccessToken } : { lib };

  return (
    <VisualiserProvider
      is3DEnabled={lib === "mapbox"}
      initialState={initialVisualiserState}
      toolsets={toolsets}
      layerSets={layerSets}
    >
      <TimelineProvider
        defaultHours={defaultHours}
        defaultStart={defaultStart}
        isStatic={isStatic}
      >
        <PredictionsProvider>
          <HimawariTemperatureProvider>
            <MapProvider
              {...mapProviderProps}
              initialBounds={initialBounds}
              onBoundsChange={onBoundsChange}
            >
              <MapInteractionsProvider>
                <MouseProvider>{children}</MouseProvider>
              </MapInteractionsProvider>
            </MapProvider>
          </HimawariTemperatureProvider>
        </PredictionsProvider>
      </TimelineProvider>
    </VisualiserProvider>
  );
};

export default Visualiser;
